<div class="content">
  <ng-container *ngIf="subscriptionPlans$ | async as subscriptionPlans; else loading">
    <div class="plans">
      <div class="plan-item">
        <div>
          <div class="plan-title">Standard</div>
          <div>Free</div>
          <div class="plan-benefits">
            <div>What you get:</div>
            <ul>
              <li>Browse from our collection of movies and TV shows</li>
              <li>Buy or rent movies from our library</li>
              <li>Watch live stream</li>
            </ul>
          </div>
        </div>
        <div class="plan-button">
          <button
            class="button"
            [class.is-primary]="currentSubscription"
            [class.is-ghost]="!currentSubscription"
            [disabled]="!currentSubscription"
            (click)="toggleUnsubscriptionModal(true)"
          >
            {{ !currentSubscription ? 'Current Plan' : 'Subscribe' }}
          </button>
        </div>
      </div>
      <div class="plan-item">
        <div>
          <div class="plan-title">{{ subscriptionPlans[0]?.name || 'All Access' }}</div>
          <div>${{ subscriptionPlans[0]?.amount || '4.99' }}/month</div>
          <div class="plan-benefits">
            <div>What you get:</div>
            <ul>
              <li>Watch any content from our library of movies and TV shows anytime</li>
              <li>More perks coming soon</li>
            </ul>
          </div>
        </div>
        <div class="plan-button">
          <button
            class="button"
            [class.is-primary]="!currentSubscription"
            [class.is-ghost]="currentSubscription"
            [disabled]="currentSubscription"
            (click)="toggleSubscriptionModal(true)"
          >
            {{ !currentSubscription ? 'Subscribe' : 'Current Plan' }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="plans">
      <div class="plan-item-skeleton loading"></div>
      <div class="plan-item-skeleton loading"></div>
    </div>
  </ng-template>

  <app-subscription-modal
    *ngIf="merchant$ | async as merchant"
    [showModal]="isSubscribeModalOpen"
    [merchantInfo$]="merchant$"
    [userInfo$]="user$"
    [subscriptionPlans$]="subscriptionPlans$"
    (modalStatus)="toggleSubscriptionModal($event)"
    (subscriptionSuccess)="subscriptionSucess($event)"
  >
  </app-subscription-modal>

  <app-modal
    *ngIf="isUnsubscribeModalOpen"
    [showModal]="isUnsubscribeModalOpen"
    (modalStatus)="toggleUnsubscriptionModal($event)"
  >
    <div class="p-5">
      <div class="is-size-4 has-text-weight-semibold">
        <div>Are you sure you want to unsubscribe from</div>
        <div>XStreamr?</div>
      </div>
      <div class="mt-4">
        <button class="button is-primary" (click)="unsubscribe()">Continue unsubscribing</button>
        <button class="button is-outlined ml-4" (click)="toggleUnsubscriptionModal(false)">Cancel</button>
      </div>
    </div>
  </app-modal>
</div>
