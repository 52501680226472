import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subscription, SubscriptionPlan } from '@model/subscription';
import { Observable } from 'rxjs';
import { PaymentProfile } from '@model/payment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private http: HttpClient) {}

  getSubscriptionByMerchant(merchantGuid: string): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>('/api/v1/subscriptionplans/search', {
      params: {
        merchantGUID: merchantGuid
      }
    });
  }

  subscribe(subscriptionGuid: string, payload: PaymentProfile): Observable<Subscription> {
    return this.http.post<Subscription>(`/api/v1/subscriptionplans/${subscriptionGuid}/subscribe`, payload);
  }

  unsubscribe(subscriptionGuid: string): Observable<void> {
    return this.http.post<void>(`/api/v1/subscriptions/${subscriptionGuid}/unsubscribe`, undefined);
  }
}
