export const LocalStorageKey = {
  HOME_FILMS_KEY: 'HOME_FILMS',
  HOME_FILMS_BY_GENRES_KEY: 'HOME_FILMS_BY_GENRES',
  HOME_FILMS_COMING_SOON: 'HOME_FILMS_COMING_SOON',
  FILM_LIST_FILMS_BY_GENRES_KEY: 'FILM_LIST_FILMS_BY_GENRES',
  HOME_TRENDING_KEY: 'HOME_TRENDING',
  FILM_LIST_TRENDING_KEY: 'FILM_LIST_TRENDING',
  FEATURED_FILMS_KEY: 'FEATURED_FILMS',
  PREVIEW_FILM_KEY: 'PREVIEW_FILM',
  NEW_FILM_RELEASE_KEY: 'NEW_FILM_RELEASE',
  TV_SHOWS_KEY: 'TV_SHOWS',
  FILMS_DRAMA_KEY: 'FILMS_DRAMA',
  FILMS_COMEDY_KEY: 'FILMS_COMEDY',
  FILMS_DOCUMENTARY_KEY: 'FILMS_DOCUMENTARY',
  LIVE_EVENTS_KEY: 'LIVE_EVENT',
  FEATURED_ACTORS_KEY: 'FEATURED_ACTORS',
  ALL_ARTIST_KEY: 'ALL_ARTIST',
  FILMS_COMING_SOON: 'FILMS_COMING_SOON'
};
