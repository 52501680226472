import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AppInterceptor } from './app.interceptor';
import { LandingComponent } from './components/landing/landing.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { BaseComponent } from './components/base/base.component';
import { FilmComponent } from './components/film/film.component';
import { FooterComponent } from './components/footer/footer.component';
import { VideoComponent } from './components/video/video.component';
import { VideoVolumeComponent } from './components/video/volume/volume.component';
import { RegisterComponent } from './components/register/register.component';
import { ToastModule } from './shared/components/toast/toast.module';
import { CommentsComponent } from './shared/components/comments/comments.component';
import { AvatarComponent } from './shared/components/comments/avatar/avatar.component';
import { ModalModule } from './shared/components/modal/modal.module';
import { GalleryCommentsComponent } from './shared/components/gallery-comments/gallery-comments.component';
import { GalleryAvatarComponent } from './shared/components/gallery-comments/avatar/gallery-avatar.component';
import { SearchComponent } from './components/navigation/search/search.component';
import { SubmissionComponent } from './components/submission/submission.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { BuyComponent } from './components/buy/buy.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { HistogramComponent } from './components/video/histogram/histogram.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { CastComponent } from './components/cast/cast.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ViewCastCrewModule } from './shared/components/view-cast-crew/view-cast-crew.module';
import { MediaComponent } from './components/media/media.component';
import { FilmListComponent } from './components/film-list/film-list.component';
import { PeopleComponent } from './components/people/people.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FeedComponent } from './shared/components/feed/feed.component';
import { FeedLiveEventComponent } from './shared/components/feed-live-event/feed-live-event.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { VideoCallComponent } from './shared/components/video-call/video-call.component';
import { ImageViewerComponent } from './shared/components/image-viewer/image-viewer.component';
import { SwitchComponent } from './shared/components/switch/switch.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { NotificationCardComponent } from './shared/components/notification/notification-card/notification-card.component';
import { LoadingComponent } from './shared/components/feed/loading/loading.component';
import { OnboardingVideoCallComponent } from './shared/components/onboarding-video-call/onboarding-video-call.component';
import { PromosComponent } from './shared/components/promos/promos.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { ContactComponent } from './components/contact/contact.component';
import { StreamComponent } from './components/deck/stream/stream.component';
import { VideoCallEndComponent } from './shared/components/video-call-end/video-call-end.component';
import { FilmEndComponent } from './shared/components/film-end/film-end.component';
import { RateVideoMessageModule } from './shared/components/rate-video-message/rate-video-message.module';
import { EpisodesComponent } from './components/episodes/episodes.component';
import { TvShowListComponent } from './components/tv-show-list/tv-show-list.component';
import { SettingsModule } from './components/settings/settings.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DataTableModule } from './shared/components/data-table/data-table.module';
import { TipJarComponent } from './shared/components/tip-jar/tip-jar.component';
import { LiveStreamsComponent } from './components/live-streams/live-streams.component';
import { ReviewsV2Component } from './components/reviews-v2/reviews-v2.component';
import { WatchlistComponent } from './components/watchlist/watchlist.component';
import { MerchComponent } from './components/merch/merch.component';
import { MerchListComponent } from './components/merch-list/merch-list.component';
import { MusicListComponent } from './components/music-list/music-list.component';
import { MusicComponent } from './components/music/music.component';
import { MusicPlayerComponent } from './components/music-player/music-player.component';
import { SubscriptionModalComponent } from './shared/components/subscription-modal/subscription-modal.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { ApplyComponent } from './components/apply/apply.component';
import { HowComponent } from './components/how/how.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { CookiesComponent } from './components/cookies/cookies.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LandingComponent,
    NavigationComponent,
    BaseComponent,
    FilmComponent,
    FooterComponent,
    VideoComponent,
    VideoVolumeComponent,
    RegisterComponent,
    CommentsComponent,
    AvatarComponent,
    GalleryCommentsComponent,
    GalleryAvatarComponent,
    SearchComponent,
    SubmissionComponent,
    AboutUsComponent,
    BuyComponent,
    ClickOutsideDirective,
    HistogramComponent,
    ReviewsComponent,
    CastComponent,
    GalleryComponent,
    EpisodesComponent,
    MediaComponent,
    FilmListComponent,
    PeopleComponent,
    ProfileComponent,
    FeedComponent,
    FeedLiveEventComponent,
    VideoCallComponent,
    ImageViewerComponent,
    SwitchComponent,
    NotificationComponent,
    NotificationCardComponent,
    LoadingComponent,
    OnboardingVideoCallComponent,
    PromosComponent,
    FaqsComponent,
    ContactComponent,
    StreamComponent,
    VideoCallEndComponent,
    FilmEndComponent,
    TvShowListComponent,
    ForgotPasswordComponent,
    TipJarComponent,
    LiveStreamsComponent,
    ReviewsV2Component,
    WatchlistComponent,
    MerchComponent,
    MerchListComponent,
    MusicListComponent,
    MusicComponent,
    MusicPlayerComponent,
    SubscriptionModalComponent,
    SubscribeComponent,
    ApplyComponent,
    HowComponent,
    CopyrightComponent,
    CookiesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastModule,
    ModalModule,
    ViewCastCrewModule,
    NgxUiLoaderModule,
    NgScrollbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RateVideoMessageModule,
    SettingsModule,
    DataTableModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'hover',
        autoHeightDisabled: false
      }
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
