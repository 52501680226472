export const environment = {
  production: true,
  platform: 'XStreamr',
  internalPlatform: 'xstreamr',
  externalName: 'XStreamr',
  baseApiUrl: 'https://api.cosmosapps.net',
  signalRUrl: 'https://api.cosmosapps.net/hub',
  adminUrl: 'https://admin.xstreamr.com',
  domains: {
    baseUrl: 'https://xstreamr.com',
    unreelUrl: 'https://unreellive.com',
    heyFansUrl: 'https://heyfans.com'
  },
  landingFilm: '181bd6d0-e8c4-4d4d-a0a6-ceb67799faa6',
  tmdbResource: 'https://image.tmdb.org/t/p/',
  tmdbResourceThumbnail: 'https://image.tmdb.org/t/p/w500',
  tmdbApiUrl: 'https://api.themoviedb.org/3',
  tmdbApiKey: '580e60a52ac48c49cfd3ca83ce561599',
  mainMerchantGuid: '346717e8-fea3-4703-ab0c-9b36329f2f5a'
};
