<div class="mt-4" *ngIf="(totalUserCount | async) > 0">
  <div class="columns">
    <div class="column is-half field">
      <input type="text" class="input" placeholder="Search user" [formControl]="searchControl" />
    </div>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            <div class="is-flex">
              <div class="cursor-pointer" (click)="sortUsername()">User (Level 1) <i class="unreel-fa-sort"></i></div>
            </div>
          </th>
          <th scope="col">
            <div class="is-flex">
              <div>Sign-Ups <span class="h4">(Level 2)</span></div>
            </div>
          </th>
          <th scope="col" class="is-hidden-mobile">Sign-Up Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tier1User of tier1UsersDisplay$ | async">
          <td>{{ tier1User.username }}</td>
          <td>{{ tier1User.referralCount }}</td>
          <td class="is-hidden-mobile">{{ tier1User.date | date: 'shortDate' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
