import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MerchantFollower, UserFollow } from '@model/follower';
import { Merchant } from '@model/merchant';
import { User } from '@model/user';
import { MerchantService } from '@service/merchant.service';
import { UserService } from '@service/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowingComponent implements OnInit {
  constructor(private userService: UserService) {}

  followings$: BehaviorSubject<UserFollow[]> = new BehaviorSubject(null as any);

  ngOnInit(): void {
    let isGettingFollowing = false;

    this.userService.user$.subscribe((user) => {
      if (user && user.guid && !isGettingFollowing) {
        isGettingFollowing = true;
        this.getFollowing(user.guid);
      }
    });
  }

  getFollowing(guid: string) {
    this.userService
      .userFollowing(guid)
      .toPromise()
      .then((res) => {
        this.followings$.next(res.filter((following) => following.followedUserUsername !== 'xstreamr'));
      });
  }
}
