<div class="columns">
  <div class="column">
    <div *ngIf="followings$ | async as followings; else loading">
      <ng-container *ngIf="followings.length > 0; else noFollowing">
        <div class="mt-4" *ngFor="let following of followings">
          <div class="flexbox is-pointer" [routerLink]="['/profile', following.followedUserUsername]">
            <img
              class="avatar mr-2"
              [src]="following.followedUserNormalizedProfileImageUrl || 'assets/images/featured-cover-art.png'"
            />
            {{ following.followedUserDisplayName }}
          </div>
        </div>
      </ng-container>
      <ng-template #noFollowing>You are currently not following anyone.</ng-template>
    </div>
    <ng-template #loading><div class="p-6 has-text-centered">Loading users...</div></ng-template>
  </div>
</div>
