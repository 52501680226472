import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { ColDef } from 'ag-grid-community';
import { environment } from '@environment';
import { UserService } from '@service/user.service';
import { SubscriptionService } from '@service/subscription.service';
import { Subscription } from '@model/subscription';
import { MerchantRendererComponent } from 'app/shared/components/data-table/cell-renderers/merchant/merchant-renderer.component';
import { UnsubcribeCallActionRendererComponent } from '../unsubcribe-call-action-renderer/unsubcribe-call-action-renderer.component';

@Component({
  selector: 'app-other-creators',
  templateUrl: './other-creators.component.html',
  styleUrls: ['./other-creators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherCreatorsComponent implements OnInit {
  subscriptions$ = new BehaviorSubject<Subscription[] | undefined>(undefined);
  subscription: Subscription | undefined = undefined;
  isUnsubscribeModalOpen = false;

  // context used for ag-grid
  public context: any;

  colDefs: ColDef<Subscription>[] = [
    {
      headerName: 'Name',
      headerClass: 'has-text-weight-semibold',
      flex: 1,
      minWidth: 160,
      autoHeight: true,
      wrapText: true,
      cellRenderer: MerchantRendererComponent
    },
    {
      field: 'startDate',
      headerName: 'Member',
      headerClass: 'has-text-weight-semibold',
      cellClass: 'flexbox',
      flex: 1,
      minWidth: 160,
      autoHeight: true,
      wrapText: true,
      valueFormatter: (params) => {
        const startDate = moment(params.value);
        return `${moment().diff(startDate, 'months').toLocaleString()} months`;
      }
    },
    {
      headerName: '',
      headerClass: 'has-text-weight-semibold',
      cellClass: 'flexbox is-justify-content-center',
      flex: 1,
      minWidth: 160,
      autoHeight: true,
      wrapText: true,
      cellRenderer: UnsubcribeCallActionRendererComponent,
      cellRendererParams: {
        unsubscribe: (subscription: Subscription) => {
          this.subscription = subscription;
          this.isUnsubscribeModalOpen = true;
        }
      }
    }
  ];

  constructor(private userSerivce: UserService, private subscriptionService: SubscriptionService) {
    this.context = { componentParent: this };
  }

  ngOnInit(): void {
    this.getCurrentSubscriptions();
  }

  getCurrentSubscriptions(): void {
    this.userSerivce.getCurrentUserSubscriptions().subscribe((subscriptions) => {
      const activeSubscriptions = subscriptions.filter(
        (subscription) => subscription.status === 'Active' && subscription.merchantGUID !== environment.mainMerchantGuid
      );

      this.subscriptions$.next(activeSubscriptions);
    });
  }

  toggleUnsubscriptionModal(value: boolean): void {
    this.isUnsubscribeModalOpen = value;
  }

  unsubscribe(): void {
    if (!this.subscription) return;

    this.subscriptionService.unsubscribe(this.subscription.guid).subscribe(() => {
      console.log('here');
    });

    const activeSubscriptions = this.subscriptions$.value?.filter(
      (subscription) => subscription.guid !== this.subscription?.guid
    );

    this.subscriptions$.next(activeSubscriptions);
    this.subscription = undefined;
    this.toggleUnsubscriptionModal(false);
  }
}
